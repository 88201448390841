<template>
  <div>
    <v-card-title class="headline">
      Admin Profile Viewer
      <v-spacer/>
      <v-text-field v-model="profileId" solo rounded clearable hide-details v-on:keyup.enter="search" @click:clear="resultSearch='';search()" @click:prepend="search" prepend-icon="fa-search" class="search-box" placeholder="Enter Profile Id" />
    </v-card-title>

    <v-alert v-if="error" type="error">{{error}}</v-alert>
    <v-alert v-if="response && (response.status == 404 || !profile)" type="warning">No profile found (or no permissions to view) with id '{{profileId}}'.</v-alert>
    <v-card v-if="profile">
      <v-card-title class="display-2">{{profile.name}}</v-card-title>
      <v-card-text class="mt-4">
        {{ profile.email || '- no email -' }}
        • joined: {{ profile.join_date | localDate('L LTS') }}
        • dob: {{ profile.dob | localDate('L') }}
        • gender: {{ profile.gender }}
        • id: {{ profile.id }}
        • uid: {{ data.firebase_uid }}
        • provider/SSO ID: {{ profile.account ? profile.account.provider_id || '-' : '-' }}
        • tenants: {{ data.tenants ? data.tenants.join(', ') : '-' }}
        <span v-if="profile.manual" class="font-weight-bold">* MANUAL CONNECT</span>
        <span v-if="profile.private" class="font-weight-bold">* PRIVATE PROFILE</span>
        <span v-if="profile.meta && profile.meta.some(x => x.key == 'fp.bdi')" class="font-weight-bold">* FAIRPLAY ENABLED</span>
      </v-card-text>
      <v-card-text>
        <v-btn text @click="updateEmail">Update Email</v-btn>
        <v-btn v-if="false" text @click="toggleFairplay">Enable fairplay data collection</v-btn>
        <v-btn v-if="tenant.id === 'wmm'" text @click="profileSyncWmm">Sync badges</v-btn>
        <v-btn v-if="tenant.id === 'wmm'" text @click="merge">Merge</v-btn>
      </v-card-text>
      
      <v-divider />

      <v-card-title class="subtitle">Connections</v-card-title>
      <v-card-text>
        <v-alert v-if="this.profile.providers && this.profile.providers.length > 0" type="info">Connected using <strong>{{profile.providers.join(' + ')}}</strong>.</v-alert>
        <v-alert v-else type="info">Not connected with external service.</v-alert>

        <p v-if="!profile.sync">No activity has been synced yet. Activities you sync with a connected app are automatically synced to your sodisp profile!</p>
        <v-alert v-else-if="profile.sync.status == 'OK'" type="success">Last activity was synced successfully at {{ profile.sync.timestamp | localDate("L LTS") }}.</v-alert>
        <v-alert v-else-if="profile.sync.status == 'INFO'" type="info">Last activity sync notification was at {{ profile.sync.timestamp | localDate("L LTS") }}: <strong>{{ profile.sync.msg }}</strong></v-alert>
        <v-alert v-else type="error">There was a sync issue during the last sync at {{ profile.sync.timestamp | localDate("L LTS") }}: <strong>{{ profile.sync.msg }}</strong></v-alert>

        <h4>Tokens</h4>
        <ul>
          <li v-for="(item, idx) in data.tokens" :key="idx">{{ item.provider }} (provider id: {{ item.id }}) <v-btn text color="accent" small @click="debugProvider(item.provider)">Debug</v-btn> </li>
        </ul>
      </v-card-text>      

      <v-divider />

      <v-card-title class="subtitle">Joined events</v-card-title>
      <v-card-text v-if="!profile.events || profile.events == 0">
        <p class="mb-0">No joined events.</p>
      </v-card-text>      
      <EventGrid :items="profile.events" :routeResolver="resolveEventManagerRoute" class="mx-4 mb-4"></EventGrid>
      <v-divider />

      <div v-if="profile && profile.race_results && profile.race_results.length > 0">
        <MyRaceResultsOverview :results="profile.race_results"/>
      </div>

      <v-card-title class="subtitle">Activities</v-card-title>
      <v-card-text>
        View the details of last 25 activities.
      </v-card-text>
      <ActivityGrid :limit="50" :profile="profile" adminMode ref="activitiesGrid" :autoLoad="false" @delete="deleteActivity" />


      <div class="pa-4">
        <h3>Badges</h3>
        <p v-if="!profile.badges || !profile.badges.length">No badges earned or synced (yet).</p>
        <v-row v-if="profile && profile.badges" dense>
          <v-col v-for="(item, idx) in profile.badges" :key="idx" cols="2">
            <v-card :elevation="1" :input-value="active" color="white" max-width="100px" class="">
              <img :src="item.img" :title="item.name" class="pa-1" :style="`max-width: 100%;opacity:${item.first_reward_date ? 1 : 0.3};`"/>
            </v-card> 
          </v-col>
        </v-row>     

        <h3>Meta</h3>
        <v-data-table
          :headers="[ { text: 'Key', value: 'key' }, { text: 'Value (String)', value: 'val_str' }, { text: 'Value (Int)', value: 'val_int' }, { text: 'Value (Date)', value: 'val_dto' }, { text: 'Value (Double)', value: 'val_dbl' }  ]"
          :items="profile.meta"
          :items-per-page="50"
          class="elevation-1 mb-8"
          />
        <!-- <h3>Logs</h3>
        <v-data-table
          v-if="logs"
          :headers="[ { text: 'Time', value: 'timestamp' }, { text: 'Level', value: 'level' }, { text: 'Tag', value: 'tag' }, { text: 'Message', value: 'msg' }, { text: 'By Admin?', value: 'admin' },  ]"
          :items="logs.data"
          :items-per-page="50"
          class="elevation-1"
          /> -->
      </div>

      <MultiStepDialog v-model="showProviderResponse" title="Provider debug info" single-step>
        <pre>{{ JSON.stringify(providerResponse, null, 2) }}</pre>
      </MultiStepDialog>
    </v-card>
  </div>
</template>

<script>
import store from "@/store";
import { mapGetters } from "vuex";
import adminService from "@/services/adminService";
import activityService from "@/services/activityService";
import profileService from "@/services/profileService";
import { EventBus } from '@/plugins/eventbus.js';
import EventGrid from "@/components/EventGrid.vue";
import ActivityGrid from '@/components/ActivityGrid.vue';
import ShareButton from '@/components/ShareButton.vue';
import MyRaceResultsOverview from '@/components/MyRaceResultsOverview.vue';
import MultiStepDialog from '@/components/generic/MultiStepDialog.vue';
import tenants from '@/data/tenants.config'
const tenant = tenants.current();

export default {
  name: "Profile",
  components: {
    EventGrid,
    ActivityGrid,
    ShareButton,
    MyRaceResultsOverview,
    MultiStepDialog,
  },
  props: {
  },
  data() {
    return {
      tenant: tenant,
      profileId: null,
      response: null,
      profile: null,
      error: null,
      data: null,
      feedItems: [],
      providerResponse: null,
      showProviderResponse: false,
      activityContinuationToken: null,
    };
  },
  async mounted() {
    this.profileId = this.$route.params.profileId;

    await this.getProfile();
    
    EventBus.$on('login-state-change', async user => {
      await this.getProfile();
    });
  },
  methods: {

    async search() {
      await this.getProfile();
    },
    async getProfile() {
      this.profile = null;
      this.response = null;
      if (this.user && this.profileId) {
        profileService.impersonationProfileId = this.profileId;
        adminService.impersonationProfileId = this.profileId;
        activityService.impersonationProfileId = this.profileId;
        //store.commit('SET_IMPERSONATION_PROFILE_ID', this.profileId);
        try {
          //this.response = await profileService.get(/* full: */ true);
          this.response = await adminService.getProfile(this.profileId);
          this.data = this.response.data;
          this.profile = this.response.data.profile;
          await this.$nextTick();
          
          let activityResponse = await activityService.allPaged(50, this.activityContinuationToken);
          this.activityContinuationToken = activityResponse.data.meta.continuation_token;
          this.$refs.activitiesGrid.loadResponse(activityResponse.data);
          this.$refs.activitiesGrid.profile = this.profile;
        }
        catch (ex) {
          console.error(ex);
          this.error = "Error getting profile information. Either no permissions or not existing profile."
        }
      }

      await adminService.getCookie();
    },
    async deleteActivity(item) {
      if (confirm(`Are you sure you want to delete the ${item.type} activity from ${item.prov} at ${item.start}?`)) {
        //console.log('/// deleteActivity', item);
        await activityService.delete(item.id, /*skipReprocess:*/true);
      }
    },
    async debugProvider(provider) {
      var response = (await adminService.debugProvider(provider, this.profileId, /*daysInPast:*/30)).data;
      this.providerResponse = response;
      this.showProviderResponse = true;
      console.log('/// device info', response);
    },
    resolveEventManagerRoute(eventId) {
      return {name: 'eventmanagerParticipants', params: {id: eventId}, query: { search: this.profileId && this.profileId.slice(-4)}};
    },

    async merge() {
      var sourceProfileId = prompt('Enter the profile ID of the profile you want to merge INTO the currently opened profile'); 
      if (sourceProfileId){
        const response = (await adminService.mergeProfiles(sourceProfileId, this.profileId)).data;
        this.$helpers.toastResponse(this, response, 'Profiles have been merged.');
        await this.getProfile();
      }
    },

    async updateEmail() {
      var email = prompt('Enter new email', this.profile.email); 
      if (email){
        const response = (await adminService.updateProfile(this.profileId, email)).data;
        this.$helpers.toastResponse(this, response, 'Profile has been updated.');
        await this.getProfile();
      }
    },

    async toggleFairplay() {
      let enabled = true;
      if (confirm(`Do you want to turn fairplay ${enabled?'on':'off'} for this user?`)){
        const response = (await adminService.updateFairplayProfileFlag(this.profileId, enabled)).data;
        this.$helpers.toastResponse(this, response, 'Profile has been updated.');
        await this.getProfile();
      }
    },

    async profileSyncWmm() {
      if (confirm(`Do you want to (re)sync badges for this user?`)){
        const response = (await adminService.profileSyncWmm(this.profileId)).data;
        this.$helpers.toastResponse(this, response, 'Data is synced.');
        await this.getProfile();
      }
    },

  },
  computed: {
    connectedWithGarmin() {
      return this.profile && this.profile.providers && this.profile.providers.indexOf('garmin') >= 0;
    },
    connectedWithStrava() {
      return this.profile && this.profile.providers && this.profile.providers.indexOf('strava') >= 0;
    },
    ...mapGetters({
      user: "user"
    })
  },

};
</script>
<style lang="scss">

.search-box {
  max-width: 350px;
  float: right;
}
</style>

